import moment from "moment";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { getNewsLists } from "../../../service/news";

interface NewsData {
  id: string;
  title: string;
  url: string;
  publish_date: string;
}

const NewsCarousel = () => {
  const { data: newsData, isLoading } = useQuery<any>(
    "get-news-lists",
    getNewsLists
  );

  if (isLoading || newsData?.count === 0) {
    return <></>;
  }

  return (
    <>
      <strong className="news">News</strong>
      <Carousel
        centerMode={false}
        autoPlay={true}
        showStatus={false}
        showIndicators={false}
        axis="vertical"
        infiniteLoop
        interval={6000}
        swipeable
        showThumbs={false}
        showArrows={false}
      >
        {newsData?.data.map((news: NewsData, index: number) => (
          <a key={index} href={news.url} target="_blank">
            <div className="news-wrapper">
              <p className="date">
                {moment(news.publish_date).format("YYYY.MM.DD")}
              </p>
              <p className="message">{news.title}</p>
            </div>
          </a>
        ))}
      </Carousel>
    </>
  );
};

export { NewsCarousel };
